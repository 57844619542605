@import "../../../../../../styles/variables";

.root {

}

.title {
  a {
    text-decoration: none;
    font-family: $font-family-avnir-bold;
    font-weight: 700;
    letter-spacing: 0.02em;
    color: $blue;
    font-size: 1rem;
  }
}

.content {
  font-size: $font-size-14;
  color: $softDark;
  word-break: break-word;
}
