.wrapper {
  display: block;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.blueBackBlock {
  margin-bottom: 0.5rem;
  padding: 5px 12px;
  background-color: #1c1757;
  color: #fff;
  font-size: 1rem;
}

.redBorderBlock,
.partText {
  display: block;
}

.redBorderBlock {
  padding: 10px 12px;
  margin: 10px 0;
  border-top: 1px solid #b20000;
  border-bottom: 1px solid #b20000;
  font-size: 18px;
}
