@import "../../../styles/variables";

.root {
  position: relative;
  .controls {
    position: absolute;
    z-index: 5;
    width: 100%;
    display: flex;
    justify-content: space-between;
    top: 35%;
    pointer-events: none;
    button {
      margin: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      outline: 0 !important;
      pointer-events: all;
      color: #b4b4b4;
      border: 0;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      transition: all 250ms ease-in-out;
      &:hover:not([disabled]) {
        color: white;
      }
      &:disabled {
        opacity: 0;
        cursor: auto;
      }
    }
  }
}

.title {
  padding: 0 5px;
}
