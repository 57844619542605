@mixin hover($baseBg, $baseColor, $hoverBg, $hoverColor, $trTime: 300ms, $trType: ease) {
  background-color: $baseBg;
  color: $baseColor;
  text-decoration: none;
  transition: background-color $trType $trTime, color $trType $trTime;

  &:hover {
    text-decoration: none;
    background-color: $hoverBg;
    color: $hoverColor;
  }
}
