@use "sass:color";
@import "../../../../styles/variables";
@import "../../../../node_modules/bootstrap/scss/mixins/breakpoints";

.title {
  overflow-wrap: break-word;
  background-color: #b20000;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: 19px;
  text-align: center;
  margin: 0;
  padding: 4px;
  font-family: "Avenir Next Cyr Med", sans-serif;

  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.liveFeed {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;

  & video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    min-width: 100%;
    vertical-align: middle;
  }
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
  }
}

.button {
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
