@import "../../../../styles/variables";

.root {
  overflow-wrap: break-word;
  font-family: $font-family-avnir-medium;
  font-size: $font-size-16;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #fff;
  background-color: $blue;
  text-align: center;
  user-select: none;
  margin: 0;
  padding: 4px;

  border-radius: 4px;
}
