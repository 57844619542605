@use 'sass:color';
@import "../../../../styles/variables";
@import "../../../../node_modules/bootstrap/scss/mixins/breakpoints";

.root {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  &.logo {
    a {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}

