@use "sass:color";
@import "styles/variables";

.noItems {
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  color: #606060;
}

.category {
  // For old Safari-browser 💩
  display: table;
  white-space: nowrap;

  border: 1px solid transparent;
  background-color: $softGrey3;
  border-radius: 4px;
  color: $blue;
  font-weight: 600;
  font-size: 1rem;
  line-height: normal;
  cursor: pointer;
  padding: 0.375rem 0.675rem;

  transition: all 250ms ease-in-out;

  margin-bottom: 1rem;

  &:hover,
  &:focus {
    color: #fff;
    background-color: #1c1757cc;
  }

  &.small {
    font-size: 0.875rem;
  }
}

.show {
  display: inline-block;
  margin-bottom: 0;

  text-align: left;
  color: $lightBlue;
  font-weight: 400;
  font-size: 1.25rem;
  // font-family: $font-family-avnir;
  line-height: 1.3;

  z-index: 90;
  transition: all 250ms ease-in-out;

  &:hover,
  &:focus {
    color: $blue;
  }

  &.small {
    font-size: 1rem;
  }
}
