@use "sass:color";
@import "styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.tag {
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;

  border-radius: 3px;

  margin: 0 0.25rem 0.25rem 0;

  font-size: 0.75rem;
  text-transform: uppercase;

  background-color: $red;
  color: #fff;

  transition: all 250ms ease-in-out;
}

.link {
  display: block;
  width: 100%;
  line-height: 1.1;
  padding: 5px 7px 4px;
  height: 22px;
  margin: 0 !important;
  border-radius: 3px;

  &:hover {
    color: #fff;
    background-color: $redDark;
  }
}

.show {
  border-radius: 3px;
  background-color: $red;
}

.bold {
  font-weight: 600;
}

.large {
  font-size: 0.875rem;
  margin: 0 0.5rem 0.5rem 0;

  & .link {
    height: 24px;
  }
}

.negativeMarginBottom {
  margin-bottom: -0.5rem;
}
