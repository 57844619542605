@import "../../../styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.root {
  position: sticky;
  top: 50px;
  max-width: 500px;
  margin: 0 auto;
}

.topRoot {
  position: sticky;
  top: 50px;
  max-width: 500px;
  margin: 0 auto;
}

.wrap {
  margin-bottom: 2.5rem;
  border: $sidebar-block-border;
  border-radius: 5px;
  padding: 1.56rem;
  user-select: none;
}

.topWrap {
  border-radius: 0;
  padding: 0 0 1rem;
  padding-top: 0;
  user-select: none;
  width: 100%;
  min-width: 280px;
  max-width: $menu-sidebar-max-width;
  margin: 0 auto;

  @media (min-width: 992px) and (max-width: 1100px) {
    padding: 0;
  }
}
