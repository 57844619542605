@import "../../../styles/variables";

.control {
  outline: none;
  border: none;
  border-radius: 3px;
  background-color: rgb(177, 177, 177);
  opacity: 0.6;
  transition: all 0.2s ease-in-out;
  padding: 9px 12px;
  margin-left: 15px;
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;

  &:hover:not([disabled]) {
    opacity: 1;
    transition: all 0.2s ease-in-out;
    background-color: $softDark2;
    color: white;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &[disabled] {
    background-color: rgb(224, 224, 224);
  }
}
.controls {
  padding: 0 21px 0 0;
  margin-top: 20px;
  min-width: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
}
