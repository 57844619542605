@import "../../../styles/variables";

.root {
  width: 100%;
  min-width: $right-sidebar-min-width;
  max-width: $right-sidebar-max-width;
  margin: 0 auto;
  @media screen and (max-width: 991px) {
    margin-top: 0.5rem;
  }
}

.sidebar {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 991px) {
    margin-top: 0.5rem;
  }
}
