.root {
  word-break: break-all;

  & video,
  & img {
    max-width: 100%;
    height: auto;
  }

  & iframe {
    width: 100%;
  }
}
