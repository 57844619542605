@import "../../../../../styles/variables";

.root {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;

  span {
    cursor: pointer;
    display: inline-block;
    padding: 1.5rem 0 0.5rem;

    i {
      display: inline-block;
      width: $font-size-10;
      height: $font-size-10;
      background-color: $sidebar-block-indicator-bg;
      border-radius: 50%;
    }

    &:hover {
      i {
        background-color: $sidebar-block-indicator-hover-bg;
      }
    }

    &.active {
      i {
        background-color: $sidebar-block-indicator-active-bg;
      }
    }
  }
}

.dot {
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  display: inline-block;
  outline: 0 !important;

  &:disabled {
    i {
      background-color: $sidebar-block-indicator-hover-bg;
    }
  }
}
