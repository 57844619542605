@import "../../../../styles/variables";

@keyframes video-icon-hover {
  from {
    transform: scale3d(1, 1, 1);
    background-color: $btn-play-hover-bg;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1);
    background-color: $btn-play-bg;
  }

  to {
    transform: scale3d(1, 1, 1);
    background-color: $btn-play-hover-bg;
  }
}

@keyframes video-icon-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.root {
  width: 66px;
  height: 66px;
  background-color: $btn-play-bg;
  position: relative;
  display: block;
  cursor: pointer;
  border-radius: 50%;
  backface-visibility: hidden;
  transition: background-color 0.3s, opacity 0.3s, visibility 0.3s,
    border-color 0.3s;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border: solid transparent;
    border-width: 15px 25px;
    border-left-color: $btn-play-arrow-bg;
    top: 50%;
    left: 50%;
    transform: translateX(-18%) translateY(-50%);
    transition: border-color 0.3s;
    backface-visibility: hidden;
  }

  &:before {
    content: "";
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top-color: white;
    border-right-color: rgba(255, 255, 255, 0.68);
    border-bottom-color: white;
    border-left-color: rgba(255, 255, 255, 0.68);
    border-radius: 50%;
  }

  &:hover {
    animation: videoiconhover 1.5s infinite ease-in-out;
  }
}

.big {
  width: 140px;
  height: 140px;
}

.big:before {
  border-width: 5px;
}

.big:after {
  border-width: 25px 45px;
}

.active {
  background-color: $btn-play-active-bg;

  &:after {
    border-left-color: $btn-play-arrow-active-bg;
  }
  &:not(.loading) {
    background-color: $btn-play-bg;
  }
}

.loading {
  &:before {
    display: block;
    animation: video-icon-loading 1.2s linear infinite;
  }
}
