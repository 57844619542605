@import "styles/variables";

.root {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  & > div {
    color: $darkCyan;
    text-transform: uppercase;
    font-size: $font-size-12;
    font-family: $font-family-avnir;
    font-weight: normal;
    letter-spacing: 0;

    & > abbr {
      width: 100%;
      display: inline-block;
      padding-left: 0.312rem;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-decoration: none;
      cursor: auto;
    }
  }
}

.small {
  font-size: 12px;
}

.clamp {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
