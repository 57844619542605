@import "../../../styles/variables";

.control {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  position: absolute;
  z-index: 2;
  width: 40px;
  height: 40px;
  padding: 9px;
  top: 50%;
  margin-top: -20px;
  border: none;
  border-radius: 3px;
  transition: all 250ms ease-in-out;
  &:disabled {
    display: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: $softDark2;
  }
  &.prev {
    left: 0;
  }
  &.next {
    right: 0;
  }
}

.dotsWrap {
  margin-right: -5px;
  margin-top: 5px;
  background-color: transparent;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  .dot {
    padding: 0;
    margin-right: 5px;
    border: none;
    border-radius: 4px;
    outline: 2px solid transparent;
    flex: 1 1;
    &:disabled,
    &:focus {
      outline-color: $red;
    }
  }
}

.dotsInnerWrap {
  width: 100%;
}

.viewAll {
  background-color: $red;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #fff;
  font-size: $font-size-16;
  text-align: center;
  margin: 0;
  cursor: pointer;
  font-family: $font-family-avnir-bold;
  transition: background-color ease 0.3s;
  border: none;
  border-radius: 0;
  padding: $font-size-10 $font-size-20;

  &:hover {
    background-color: #1e2e4a;
    text-decoration: underline;
  }
}
