@import "../../../../styles/variables";

.card {
  height: 100%;
  display: block;
  border-radius: 5px;
  overflow: hidden;
  &:hover {
    text-decoration: none;
  }
}

.thumbnail {
  height: 100%;
  display: block;
  position: relative;
  width: 100%;
  background: url("https://cdn.americasvoice.news/static/images/placeholders/thumbnail-16x9.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  & > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 4px;
  }
}
