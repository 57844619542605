@import "../../../../../../styles/variables";

.root {
  display: block;
  position: relative;
  width: 100px;
  height: 100px;
  background-color: $sidebar-block-preview-bg;
  flex: 0 0 100px;
}

.thumbnail {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
