.wrapper {
  position: relative;

  height: 120px;
  overflow: hidden;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 120px;
  object-position: left top;
  object-fit: cover;
}
