@import "../../../../styles/variables";

.root {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 15px;
  color: #fff;
}

.card {
  position: relative;
  height: 200px;
  overflow: hidden;
  margin-bottom: 20px;
  width: calc(50% - 10px);
  min-width: 100px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;

  &:hover .flipBox {
    transform: none;
    visibility: visible;
    opacity: 1;
  }
}

.itemImage {
  background-color: #1c1757;
  padding: 25px;
  position: absolute;
  height: 100%;
  width: 100%;

  h4 {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-family: "Avenir Next Cyr Med", sans-serif;
    font-weight: 600;
    padding-top: 10px;
    margin: 10px 0;
  }

  p {
    color: #fff;
    font-size: 11px;
    line-height: 14px;
    font-family: "Avenir Next Cyr", sans-serif;
    margin: 0 0 10px;
    padding-top: 8px;
    padding-bottom: 7px;
  }
}

.flipBox {
  transition: all 0.6s ease-in-out;
  transform: translateY(-100%);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  background-color: $redLight;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #fff;
  }
}

.flipBoxTitle {
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 5px;
  padding: 0;
}

.flipBoxDescription {
  font-size: 10px;
  margin: 0 0 5px;
  padding: 0;
}

.flipBoxOverlay {
  padding: 0px 10px 0;
}

.flipBoxBtn {
  color: #ffffff;
  background-color: #cc0000;
  background: linear-gradient(to left bottom, #cc0000 50%, #b80000 50%);
  padding: 12px 20px 12px 20px;
  position: relative;
  z-index: 0;
  border: 2px solid #fff;
  font-size: 10px;
  margin-top: 5px;
  border-radius: 4px;
  &:hover {
    background-color: black;
    background: linear-gradient(to left bottom, #1f1f1f 50%, black 50%);
  }
}
