@import "../../../../styles/variables";

.root {
  padding: 5px;
}

.card {
  display: block;
  border-radius: 4px;
  position: relative;
  width: 100%;
  background: url(https://cdn.americasvoice.news/static/images/placeholders/thumbnail-16x9.jpg)
    no-repeat;

  background-size: cover;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: -webkit-gradient(
      linear,
      right top,
      left bottom,
      color-stop(50%, rgba(0, 0, 0, 0)),
      color-stop(50%, rgba(0, 0, 0, 0.1))
    );
    background: linear-gradient(
      to left bottom,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.1) 50%
    );
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    -webkit-transition: opacity 0.3s, visibility 0.3s;
    transition: opacity 0.3s, visibility 0.3s;
    padding-top: 42.5%;
  }
  &:hover {
    text-decoration: none;
  }
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}

.content {
  display: block;
  margin-bottom: 0;
}

.title {
  font-family: $font-family-avnir-bold;
  font-size: $font-size-16;
  color: $news-cards-read-more-color;
  transition: color ease 0.3s;
  z-index: 1;
  display: block;
  padding-bottom: 1rem;
  &:hover {
    color: $news-cards-read-more-hover-color;
    text-decoration: underline;
  }
}
.excerpt {
  font-size: 0.875rem;
  color: #333;
  word-break: break-word;
  min-height: 70px;
}
