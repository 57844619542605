@import "../../../../../../styles/variables";

.root {
  display: block;
  position: relative;
  width: 100%;
  background-color: $sidebar-block-preview-bg;
  padding-top: 56.25%;
  border-radius: 4px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $sidebar-block-preview-bg-hover;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &:hover {
    &:before {
      opacity: 1;
      visibility: visible;
    }

    .playAll {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      border-radius: 2px;
    }
    .previewBtn {
      opacity: 1;
      visibility: visible;
    }

    .title {
      color: $red;
    }
  }
}

.thumbnail {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 4px;
}

.count,
.playAll,
.duration {
  display: inline-block;
  pointer-events: none;
  position: absolute;
  background-color: $sidebar-block-preview-label-bg;
  font-size: $font-size-12;
  font-family: $font-family-avnir;
  padding: 0 0.4em;
  top: 20px;
  color: #fff;
  text-transform: uppercase;

  & > svg {
    margin-right: 0.5em;
  }
}

.duration {
  top: auto;
  bottom: 3px;
}

.count {
  left: 20px;
}

.playAll {
  right: 20px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(5px);
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
}

.duration {
  right: 4px;
  bottom: 4px;
  padding: 3px 4px;
  font-family: Roboto, Arial, sans-serif;
  line-height: 1;
  border-radius: 4px;
  font-weight: 500;
}

.playBtn {
  position: absolute;
  padding: 5px;
  width: 76px;
  height: 76px;
  top: 50%;
  left: 50%;
  margin-left: -38px;
  margin-top: -38px;
}

.title {
  font-size: $font-size-12;
  font-family: $font-family-avnir;
  text-transform: initial;
  line-height: 1.1;
  display: inline-block;
  position: absolute;
  bottom: 10px;
  left: 20px;
  right: 20px;
  transition: color 0.1s;
}

.previewBtn {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 40px;
  top: 20px;
  left: 20px;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: background-color 0.3s, opacity 0.3s, visibility 0.3s,
    border-color 0.3s;
  user-select: none;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  border-radius: 0 10px 0 10px;

  &:before {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    right: -2px;
    bottom: -2px;
    background-color: #ffffff;
    position: absolute;
    opacity: 0;
    border-radius: 0 10px 0 10px;
    transition: opacity 0.2s 0s;
    z-index: -1;
  }

  &:after {
    content: "";
    line-height: 40px;
    display: block;
    width: 40px;
    height: 40px;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: -1;
    border-radius: 0 10px 0 10px;
    transition: background-color 0.3s 0.2s;
  }

  &:hover {
    &:before {
      opacity: 1;
      transition: opacity 0.2s 0.1s;
    }
    &:after {
      background-color: $red;
      transition: background-color 0.3s;
    }
  }
}
