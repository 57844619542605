@import "../../../../../styles/variables";

.root {
  word-break: break-all;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $font-family-avnir-bold;
  font-size: $font-size-14;
  text-transform: uppercase;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid $sidebar-block-border-color;
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 3px;
    background-color: $redLight;
    position: absolute;
    bottom: -2px;
    left: 0;
  }
}


