@import "../../../../../styles/variables";

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $font-family-avnir-bold;
  font-size: $font-size-14;
  text-transform: uppercase;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid $sidebar-block-border-color;
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 40px;
    height: 3px;
    background-color: $redLight;
    position: absolute;
    bottom: -2px;
    left: 0;
  }
}
.btnWrap {
  min-width: 54px;
  display: flex;
  justify-content: center;
}

.control {
  outline: none;
  border: none;
  border-radius: 3px;
  background-color: #b1b1b1;
  opacity: 0.6;
  transition: all 0.2s ease-in-out;
  padding: 5px 8px;
  margin-left: 2px;
  color: #000000;
  display: flex;
  align-items: center;
  &:hover:not([disabled]) {
    opacity: 1;
    transition: all 0.2s ease-in-out;
    background-color: #666;
    color: white;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &[disabled] {
    background-color: #e0e0e0;
    cursor: default;
  }
}
