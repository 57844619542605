@import "../../../../../styles/variables";

.root {
  padding: 5px;
}

.card {
  display: block;
  border: 2px solid #efefef;
  border-radius: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  &:hover {
    text-decoration: none;
  }
}

.thumbnail {
  display: block;
  position: relative;
  width: 100%;
  // padding-top: 52.3%;
  aspect-ratio: 16/9;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}

.content {
  padding: 1rem 2rem;
  display: block;
}

.title {
  font-family: $font-family-avnir-bold;
  font-size: 2rem;
  line-height: 1.1;
  text-decoration: none;
  margin: 0;
  padding-bottom: 1rem;
  min-height: 7.6rem;
  word-break: break-word;
}

.readMore {
  font-family: $font-family-avnir-bold;
  font-size: $font-size-16;
  color: $news-cards-read-more-color;
  transition: color ease 0.3s;
  z-index: 1;

  &:hover {
    color: $news-cards-read-more-hover-color;
    text-decoration: underline;
  }
}

.medium,
.small {
  .card {
    border: none;
    box-shadow: none;
  }
  .content {
    padding: 1rem 0;
  }
}

.medium {
  .title {
    font-size: $font-size-20;
    min-height: auto;
  }
}

.small {
  .title {
    font-size: $font-size-16;
    min-height: auto;
  }
}

.medium,
.small {
  @media all and (max-width: 768px) {
    .title {
      font-size: $font-size-20;
    }
  }
}
