@import "../../../../../styles/variables";

.root {
  display: block;
  position: relative;
  width: 100%;
  background-color: $sidebar-block-preview-bg;
  padding-top: 56.25%;
  border-radius: 4px;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $sidebar-block-preview-bg-hover;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &:hover {
    &:before {
      opacity: 1;
      visibility: visible;
    }

    .playAll {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      border-radius: 2px;
    }
  }
}

.thumbnail {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  aspect-ratio: 16 / 9;
}

.playBtn {
  position: absolute;
  padding: 5px;
  width: 76px;
  height: 76px;
  top: 50%;
  left: 50%;
  margin-left: -38px;
  margin-top: -38px;
}

.title {
  font-size: 1rem;
  color: #ffffff;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  padding: 0.65rem;
  font-family: $font-family-avnir;
  display: flex;
  align-items: flex-end;
  margin: 0;

  & > a {
    position: absolute;
    bottom: 0;
    font-family: $font-family-avnir;
  }
}

.absolutGradient {
  position: absolute;
  top: 20%;
  width: 100%;
  max-width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.66;
  pointer-events: none;
  z-index: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
}
