@import "../../../../styles/variables";
@import "../../../../styles/mixins/button";

.title {
  color: $text-color-gray;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: $text-letter-spacing-tight;
  text-transform: uppercase;
  font-family: $font-family-avnir-medium;
  font-weight: 500;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.button {
  @include btn-red-hover;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0 0.5rem;
  border-radius: 3px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

@media (max-width: 320px) {
  .listSidebar {
    flex-wrap: wrap !important;
    justify-content: center !important;
  }
}

@media (min-width: 400px) {
  .button {
    padding: 0 1rem;
  }
}

.item {
  width: 61px;
  height: 61px;
  perspective: 1000px;

  img {
    height: 100%;
  }

  &:hover {
    .flipper {
      transform: rotateX(180deg);
      .sprite {
        transition-delay: 0.2s;
        transform: rotateX(180deg);
      }
      .sprite-roku {
        background-position: 121px;
      }
      .sprite-android {
        background-position: 0;
      }
      .sprite-apple {
        background-position: 483px;
      }
      .sprite-apple-tv {
        background-position: 362px;
      }
      .sprite-fire-tv {
        background-position: 242px;
      }
    }
  }
}

.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  transform-origin: 100% 30px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .sprite {
    width: 100%;
    height: 100%;
    background-size: cover;
    transition-delay: 0.2s;
  }
}
.sprite-roku {
  background-position: 61px;
}
.sprite-android {
  background-position: -60px;
}
.sprite-apple {
  background-position: 423px;
}
.sprite-apple-tv {
  background-position: 302px;
}
.sprite-fire-tv {
  background-position: 182px;
}

.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
