@import "../../../styles/_variables";

.closeButton {
	position: absolute;
	top: 0;
	right: 50%;
	transform: translate(50%, -50%);
	width: 90px;
	height: 90px;
	padding-bottom: 5px;
	border-radius: 50%;
	border: none;
	background-color: $red2;
}

.thanksHeader {
	text-align: center;
	font-size: 30px;
	margin-top: 40px;
	margin-bottom: 20px;
}
