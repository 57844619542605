@import "../../../../../../styles/variables";
@import "../../../../../../node_modules/bootstrap/scss/mixins/breakpoints";

.root {
  display: flex;
  flex-wrap: nowrap;
  padding: 1rem 0;
  border-bottom: 1px solid $sidebar-list-block-border-color;
  @media screen and (max-width: 1300px) and (min-width: 992px) {
    flex-wrap: wrap;
    .info {
      padding-left: 0;
      padding-top: 1rem;
    }
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.info {
  padding-left: 1rem;
}

.title {
  margin: 0;
  font-size: $font-size-12;

  a {
    text-decoration: none;
    font-family: $font-family-avnir;
    letter-spacing: 0.02em;
    color: $sidebar-list-block-title-color;

    &:hover {
      color: $sidebar-list-block-title-hover-color;
    }
  }
}
